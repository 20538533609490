import { registerApplication, start, navigateToUrl } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { getIpStackInfo } from "./ip-stack-api";
import { getChatbotProps } from "./get-chatbot-props";
import {setCanonicalURL, addAlternateLinks} from './seoLinks';

setCanonicalURL();
addAlternateLinks();
const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

const COUNTRY_HM_URL = JSON.parse(process.env.HM_LANG_PATH);

applications.forEach((a) =>
  registerApplication({
    ...a,
    customProps: {
      flightBoxProps: {
        isTitleComponentShow: false,
        isRetail:true,
        initialSaleUrl: process.env.APP_INITIAL_SALE_URL,
      },
      getIpStackInfo,
      getChatbotProps,
      COUNTRY_HM_URL,
    },
  })
);
layoutEngine.activate();

const baseUrlRedirect = async () => {
  const retailUrl = new URL(window.location);
  const { pathname } = retailUrl;

  if (pathname !== "/") return;
  const response = await getIpStackInfo();
  const { country } = response;
  if (COUNTRY_HM_URL[country]) {
    navigateToUrl(
      `/${COUNTRY_HM_URL[country].defLang}${COUNTRY_HM_URL[country].url}`
    );
  } else {
    navigateToUrl(`/es/otros`);
  }
};
baseUrlRedirect();

start();
