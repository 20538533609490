

export function setCanonicalURL() {
        let canonicalLink = document.querySelector("link[rel='canonical']");
        if (!canonicalLink) {
          canonicalLink = document.createElement("link");
          canonicalLink.setAttribute("rel", "canonical");
          document.head.appendChild(canonicalLink);
        }
        let getUrl = window.location.hostname + window.location.pathname;
        canonicalLink.setAttribute("href", getUrl);
      }

export function addAlternateLinks() {
   const links = [  
    { hreflang: "x-default", href: "https://www.skyairline.com/es/chile" },
    { hreflang: "es-CL", href: "https://www.skyairline.com/es/chile" },
    { hreflang: "en-US", href: "https://www.skyairline.com/en/unitedstates" },
    { hreflang: "es-US", href: "https://www.skyairline.com/es/unitedstates" },
    { hreflang: "pt-BR", href: "https://www.skyairline.com/pt/brasil" },
    { hreflang: "en-BR", href: "https://www.skyairline.com/en/brasil" },
    { hreflang: "es-AR", href: "https://www.skyairline.com/es/argentina" },
    { hreflang: "en-AR", href: "https://www.skyairline.com/en/argentina" },
    { hreflang: "es-PE", href: "https://www.skyairline.com/es/peru" },
    { hreflang: "en-PE", href: "https://www.skyairline.com/en/peru" },
    { hreflang: "en-CL", href: "https://www.skyairline.com/en/chile" },
    { hreflang: "es-OT", href: "https://www.skyairline.com/es/otros" },
    { hreflang: "en-OT", href: "https://www.skyairline.com/en/otros" },

   ];
    links.forEach(linkData => {
        const linkElement = document.createElement("link");
        linkElement.setAttribute("rel", "alternate");
        linkElement.setAttribute("hreflang", linkData.hreflang);
        linkElement.setAttribute("href", linkData.href);
        document.head.appendChild(linkElement);
      }
    );
    }